@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/magnific-popup/dist/magnific-popup";

html {
	background: $color-turtuoise;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;

	main {
		flex: 1;
	}
}

h2 {
	text-transform: uppercase;
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-white;
	}
}

.btn {
	background: transparent linear-gradient(180deg, #BCBFA6 0%, #9F9D88 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border: 2px solid $color-taupe;
	text-transform: uppercase;
}

header {
	img {
		box-shadow: 0px 3px 6px #00000029;
		width: 100%;
	}

	img.title-bar {}

	img.sub-title-bar {}
}

main {
	display: flex;
	align-items: center;
	position: relative;

	@include tablet-down {
		padding-top: rems(20);
		flex-direction: column;
	}

	#main-container {
		display: none;
	}

	.container {
		flex: 1;
		padding: 0;
		width: 100%;
		z-index: 10;

		@include tablet-down {
			padding: 0 rems(10);
			max-width: rems(700);
		}

		.main-window {
			border: 2px solid $color-taupe;
			box-shadow: 18px 18px 20px #00000029;

			.title-bar {
				width: 100%;
			}

			.mobile-navigation-view {
				color: $color-taupe;
				display: none;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: $color-mimosa;

				@include tablet-down {
					display: flex;
				}

				h1 {
					margin: 0 rems(20);
					color: $color-taupe;
					font-size: rems(42);
					text-align: center;

					@include tablet-down {
						font-size: rems(32);
						margin-top: rems(10);
					}

					@include phone-down {
						font-size: rems(26);
					}

					span {
						display: inline-block;
						transform: translateY(-10%);
					}
				}

				.spacer {
					padding: rems(8) rems(20);
				}

				ul.nav-menu {
					list-style: none;
					padding: 0;

					li {
						a.nav-item {
							display: block;
							font-size: rems(18);
							font-family: $tertiary-font;
							color: $color-taupe;
							text-transform: uppercase;
							line-height: rems(23);
							padding-left: rems(4);

							@include hover-focus {
								background-color: $color-spring-rain;
							}
						}
					}
				}

				.shop-wrapper {
					flex: 1;

					h2 {
						text-transform: uppercase;
						margin: 0;
						font-size: rems(22);
					}

					.btn {
						display: flex;
						align-items: center;
						justify-content: center;
						gap: rems(4);
						width: 100%;
						max-width: rems(266);
						height: rems(45);
						margin: rems(10) 0 rems(16);
						color: $color-mimosa;
						font-size: rems(14);
					}
				}
			}

			.main-window-content {
				background-color: $color-mimosa;
				padding: rems(16);
				width: 100%;

				.decorative-border {
					display: flex;
					gap: rems(16);
					padding: rems(16);
					border: 1px solid $color-limed-oak-60;

					.navigation-desktop {
						flex: 1;
						color: $color-taupe;
						display: flex;
						flex-direction: column;
						min-height: rems(600);

						@include tablet-down {
							display: none;
						}

						h1 {
							margin: 0;
							color: $color-taupe;
							font-size: rems(42);
							text-align: center;

							@include tablet-down {
								font-size: rems(32);
							}

							@include phone-down {
								font-size: rems(26);
							}

							span {
								display: inline-block;
								transform: translateY(-10%);
							}
						}

						.spacer {
							padding-top: rems(8);
						}

						ul.nav-menu {
							list-style: none;
							padding: 0;

							li {
								a.nav-item {
									display: block;
									font-size: rems(18);
									font-family: $tertiary-font;
									color: $color-taupe;
									text-transform: uppercase;
									line-height: rems(23);
									padding-left: rems(4);

									@include hover-focus {
										background-color: $color-spring-rain;
									}
								}
							}
						}

						.shop-wrapper {
							flex: 1;

							h2 {
								text-transform: uppercase;
								margin: 0;
								font-size: rems(22);
							}

							.btn {
								display: flex;
								align-items: center;
								justify-content: center;
								gap: rems(4);
								width: 100%;
								max-width: rems(266);
								height: 100%;
								max-height: rems(45);
								margin: rems(10) 0 rems(16);
								color: $color-mimosa;
								font-size: rems(14);
							}
						}
					}

					.section-view {
						flex: 2;
						color: $color-taupe;
						position: relative;

						section {
							display: flex;
							height: 100%;
							max-height: rems(590);

							@include tablet-down {
								flex-direction: column;
								max-height: none;
							}

							.wrapper {
								flex: 1;
								margin-right: rems(4);
								background-color: $color-spring-rain;
								display: flex;
								padding: rems(6);

								@include tablet-down {
									margin-bottom: rems(20);
								}

								.decorative-border-page-content {
									border: 1px solid $color-sage;
									flex: 1;
									padding: rems(20) 0 rems(20) rems(20);

									>h2,
									>p {
										font-family: $tertiary-font;
										font-size: rems(40);
										margin: 0 rems(20) rems(6) 0;
										font-weight: 100;
									}
								}
							}

							.feature-card {
								flex: 1;
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								// max-width: rems(386);

								.image-wrapper {
									flex: 1;
									width: 100%;
									margin-bottom: rems(6);

									img.featured-image {
										height: 100%;
										width: 100%;
										object-fit: cover;
									}
								}

								.card-content {
									position: relative;

									img {
										width: 100%;
									}

									.text-wrapper {
										position: absolute;
										left: 50%;
										top: 40%;
										transform: translate(-50%, -50%);
										color: $color-mimosa;
										width: 100%;
										padding: 0 rems(32);

										p.quote {
											font-family: $tertiary-font;
											font-size: rems(20);
											text-transform: uppercase;
										}

										p.credit {
											text-align: right;
											font-size: rems(20);
											text-transform: uppercase;
										}
									}
								}
							}
						}

						section#home {
							img {
								object-fit: cover;
								object-position: center;
							}
						}

						section#tour {
							.wrapper {
								.decorative-border-page-content {
									h2 {}

									.tour-keys {
										display: flex;
										border-top: 1px solid $color-sage;
										border-bottom: 1px solid $color-sage;
										padding: rems(6) 0;
										margin-right: rems(20);

										.key-date {
											flex: 1;
										}

										.key-time {
											flex: 1;
										}

										.key-venue {
											flex: 1;
										}

										.key-city {
											flex: 3;
											text-align: right;
										}
									}

									.scroll-wrapper {
										overflow-y: scroll;
										height: 100%;
										max-height: rems(450);

										#tour-dates {
											margin-right: rems(20);

											a.link {

												margin: rems(24) 0;

												@include hover-focus {
													background-color: #00000029;
												}

												.event-group {
													display: flex;
													font-size: rems(12);
													font-family: $tertiary-font;
													border-bottom: 1px solid $color-sage;
													padding: rems(10) 0;


													.event-date {
														flex: 1;
													}

													.event-time {
														flex: 1;
													}

													.event-venue {
														flex: 2;
													}

													.event-location {
														flex: 2;
														text-align: right;
													}

												}
											}
										}
									}
								}
							}

						}

						section#bio {
							.wrapper {
								.decorative-border-page-content {

									h2 {}

									.scroll-wrapper {
										overflow-y: scroll;
										height: 100%;
										max-height: rems(450);

										p.bio-content {
											font-size: rems(15);
											line-height: rems(18);
											margin-right: rems(20);
										}
									}
								}
							}
						}

						section#media {
							.wrapper {
								.decorative-border-page-content {
									overflow-y: scroll;

									h2 {}

									.audio-keys {
										display: flex;
										border-top: 1px solid $color-sage;
										border-bottom: 1px solid $color-sage;
										padding: rems(6) 0;
										margin-right: rems(20);

										p {
											margin: 0;
										}

										p.key-title {
											flex: 2;
										}

										p.key-media {
											flex: 1;
										}

										p.key-listen {
											flex: 2;
										}
									}

									.audio-playlist {
										margin-right: rems(20);
										margin-bottom: rems(100);

										.music-item {
											display: flex;
											align-items: center;
											font-size: rems(12);
											line-height: rems(14);
											margin-bottom: rems(7);
											padding: rems(4);

											@include hover-focus {
												background-color: $color-mimosa;
												color: $color-taupe;
											}

											p {
												margin: 0;
											}

											p.track-name {
												flex: 2;
											}

											p.media-type {
												flex: 1;
											}

											.social-group {
												flex: 2;
												display: flex;
												gap: rems(6);

												a {
													display: block;
													width: 100%;
													max-width: rems(14);

													img {
														width: 100%;

													}
												}
											}
										}
									}

									.videos {
										margin-right: rems(20);

										.embed-container {
											margin-bottom: rems(10);
										}

										p.video-title {
											font-family: $tertiary-font;
											font-size: rems(10);
											text-transform: uppercase;
										}
									}
								}
							}
						}

						section#gallery {
							.wrapper {
								.decorative-border-page-content {
									h2 {}

									.gallery-view {
										margin-right: rems(20);
										display: flex;
										flex-wrap: wrap;
										gap: rems(4);

										.img-wrapper {
											flex-basis: 32%;

											@include tablet-down {
												flex-basis: 49%;
											}

											img {
												padding: rems(4);
												border: 1px solid $color-sage;
												margin-bottom: rems(4);
												object-fit: cover;
												aspect-ratio: 1/1;
											}
										}
									}
								}
							}
						}






						.tab-group {
							position: absolute;
							bottom: 0;
							left: 20%;
							display: flex;
							gap: rems(16);

							.tab {
								background-color: $color-mimosa;
								border-radius: rems(8) rems(8) 0 0;
								padding: rems(2) rems(8);
								transition: all .3s ease-in-out;

								@include hover-focus {
								background-color: $color-taupe;		
								color: $color-mimosa;
								}

								a {
									font-family: $secondary-font;
									font-size: rems(12);
									text-transform: uppercase;
								}
							}
						}

						.tab-group-social {
							position: absolute;
							bottom: 0;
							right: 5%;
							display: flex;
							gap: rems(4);

							@include tablet-down {
								display: none;
							}

							.tab {
								background-color: $color-mimosa;
								border-radius: rems(8) rems(8) 0 0;
								padding: rems(2) rems(8);

								a {
									display: block;
									font-size: rems(12);
									transform: translateY(rems(2));

									@include hover-focus {
										opacity: .6;
									}
								}
							}
						}

					}
				}
			}
		}
	}



	.album-promo {
		position: absolute;
		left: 5%;
		bottom: rems(10);
		background-color: $color-mimosa;
		border: 2px solid $color-taupe;
		z-index: 6;
		display: none;

		@include tablet-down {
			position: static;
			margin: rems(20) 0;
		}

		&.active {
			z-index: 21;
		}

		.promo-bg {
			width: 100%;
		}

		.wrapper {
			position: relative;
			width: 100%;
			flex: 1;
			padding: rems(36);
			display: flex;
			align-items: stretch;
			justify-content: center;

			.promo-content {
				padding: 0 rems(20);
				color: $color-taupe;
				text-align: center;

				.featured-image {
					max-width: rems(175);
				}

				h2 {}

				p {
					text-transform: uppercase;
				}

				.button-group {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: rems(10);

					.btn {
						color: $color-mimosa;
						font-size: rems(8);
						padding: rems(9) 0;
						display: block;
						width: rems(82);
						text-transform: uppercase;
					}
				}
			}
		}
	}


	.bg-element {
		position: absolute;
		z-index: -1;
	}

	#messenger {
		left: rems(4);
		top: rems(10);
		z-index: 5;
		display: none;

		@include tablet-down {
			top: rems(15);
			display: none;
		}

		&.active {
			z-index: 20;
		}
	}

	#buddy-list {
		z-index: 5;
		right: rems(4);
		top: rems(16);
		display: none;

		@include tablet-down {
			display: none;
		}

		&.active {
			z-index: 20;
		}
	}

	#time-warp {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 50;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-taupe;
		transition: opacity 1s ease;

		.container {
			background-color: $color-mimosa;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: rems(100);

			h2 {
				color: $color-taupe;
				font-size: rems(48);
			}

			.wrapper {
				display: flex;
				margin: 0 0 rems(32);
				gap: rems(20);

				.box {
					width: rems(214);
					height: rems(214);
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: $color-spring-rain;
					border: 2px solid $color-taupe;

					img.leaf {
						width: 90%;
						height: 90%;
						display: none;
					}
				}
			}

			p {
				font-size: rems(11);
			}

			p.activate-timewarp {
				color: $color-mimosa;

				&.active {
					color: $color-taupe;
				}
			}

			.loading-bar {
				position: relative;
				width: 100%;
				margin: rems(16) 0 rems(8);
				height: rems(10);

				.bar {
					background-color: $color-taupe;
					border-radius: rems(5);

					&.empty {
						height: rems(4);
						margin: rems(3) 0;
					}

					&.loaded {
						height: rems(10);
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						transition: width 1s ease;

						&.percent-3 {
							width: 3%;
						}

						&.percent-25 {
							width: 25%;
						}

						&.percent-50 {
							width: 50%;
						}

						&.percent-100 {
							width: 100%;
						}
					}
				}

			}

			.label {
				width: 100%;
				display: flex;
				justify-content: space-between;
			}
		}
	}
}

footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: $color-mimosa;
	background: transparent linear-gradient(180deg, #BCBFA6 0%, #9F9D88 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border: 2px solid $color-taupe;
	gap: rems(10);

	a.main-site {
		display: flex;
		justify-content: center;
		font-size: rems(14);
		text-transform: uppercase;
		background: transparent linear-gradient(180deg, #A5AA82 0%, #8D8857 100%) 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border: 2px solid $color-taupe;
		border-left: none;
		border-radius: 0 8px 8px 0;
		padding: rems(16) rems(30);
		width: 100%;
		max-width: rems(215);
		margin: -2px 0;
	}

	ul.social {
		display: flex;
		align-items: center;
		list-style: none;
		gap: rems(4);
		padding: 0;
		margin: 0;

		li {
			width: rems(32);
			height: rems(30);
			border-radius: rems(8);
			border: 1px solid $color-taupe;
			background-color: $color-mimosa;
			padding: rems(5);

			a {

				transition: all .3s ease-in-out;

				@include hover-focus {
					opacity: 0.5;
				}

				i {
					font-size: rems(42);
				}

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.copyright-desktop {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-family: $secondary-font;
		font-size: rems(9);
		gap: rems(4);

		@include tablet-down {

			display: none;
		}

		p {
			margin: 0;
		}
	}

	#date-time-display {
		display: flex;
		justify-content: center;
		width: 100%;
		max-width: rems(215);
		font-size: rems(14);
		text-transform: uppercase;
		background: transparent linear-gradient(180deg, #A5AA82 0%, #8D8857 100%) 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border: 2px solid $color-taupe;
		border-right: none;
		border-radius: 8px 0 0 8px;
		padding: rems(16) 0;
		margin: -2px 0;
	}
}

.sub-footer {
	background-color: $color-black;
	padding: rems(20) rems(10);
	display: none;
	font-size: rems(12);

	@include tablet-down {
		display: block;
	}

	.copyright-desktop {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-family: $secondary-font;
		font-size: rems(9);
		gap: rems(4);

		p {
			margin: 0;
		}
	}
}

.hide-on-mobile {
	@include tablet-down {
		display: none;
	}
}

.hide-on-desktop {
	@include desktop-sm-up {
		display: none;
	}
}