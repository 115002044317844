$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-taupe: #4E4137;
$color-turtuoise: #627873;
$color-mimosa: #fffbdb;
$color-mercury: #e5e5e5;
$color-limed-oak: #9E9651;
$color-limed-oak-60: #9E965199;
$color-spring-rain: #bcbfa6;
$color-sage: #9F9D88;

@font-face {
  font-family: 'DIN Pro';
  src: url('../font/DINPro.woff2') format('woff2'),
    url('../font/DINPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Alternate';
  src: url('../font/DINAlternate-Bold.woff2') format('woff2'),
    url('../font/DINAlternate-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$primary-font: 'DIN Alternate';
$secondary-font: 'hydrophilia-iced';
$tertiary-font: 'DIN Pro';